import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { DateTime } from "luxon";
import { useDownloadFile } from "../../Utils/useDownload";
import { Button, ButtonState } from "../button";
import { Alert, Container } from "react-bootstrap";
import {useAuth} from 'react-oidc-context';
import {SGD_API_HOST} from '../../env';
import { SignalRContext } from "../../App"
import {
  setLocalStorageValueWithExpiry,
  getLocalStorageValueWithExpiry,
  removeLocalStorageValueWithExpiry
} from "../../Utils/localStorageUtil"

const isReportRequestedKey = "IsBookingProgressionReportRequested"; // Key to store in local storage

const BookingProgressionReport: React.FC = (props) => {
  
  const auth =useAuth();
  const [buttonText, setButtonText] = useState<string>("Generate");
  const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Primary);
  const [isHighlightButton, setIsHighlightButton] = useState<boolean>(false);
  
  useEffect(() => {
    // Check if the item exists in localStorage on component mount
    const isReportRequested = getLocalStorageValueWithExpiry(isReportRequestedKey);
    if (isReportRequested) {
      askForProgressionReport()
    }
  }, []);

  SignalRContext.useSignalREffect(
    "BookingProgressionReportReady",
    () => {
      setButtonText("Download")
      setIsHighlightButton(true)
    },
    [],
  );

  const askForProgressionReport = async () => {
    setButtonText("Generating...")
    
    const isReportReady = await axios.get(
      SGD_API_HOST.concat("/api/Report/AskForProgressionReport"),
      {
        responseType: "json",
        method: 'get',
        headers: {
            Authorization: `bearer ${auth.user?.access_token}`},
      }
    );

    if(isReportReady.data){
      setButtonText("Download")
      setIsHighlightButton(true)
      await downloadProgressionReport()
    }
  };

  const downloadProgressionReport =  async () => {
    const report = await axios.get(
      SGD_API_HOST.concat("/api/Report/GetProgressionReport"),
      {
        responseType: "blob",
        method: 'get',
        headers: {
            Authorization: `bearer ${auth.user?.access_token}`},
      }
    );
    
    return report
  };

  let { ref, download } = useDownloadFile({
      apiDefinition: downloadProgressionReport,
      preDownloading: () => setButtonState(ButtonState.Loading),
      postDownloading: () => setButtonState(ButtonState.Primary),
      onError: () => {
        setIsHighlightButton(false)
        setButtonState(ButtonState.Primary)
        askForProgressionReport()
      },
      getFileName: () => DateTime.local().toISODate() + "_BookingProgressionReport.zip",
    });

  const handleButtonClick = async () => {
    if (buttonText === "Generate") {
      setLocalStorageValueWithExpiry(isReportRequestedKey, true, 30 * 60000); // Update localStorage, expiry - 30 mins
      await askForProgressionReport();
    } else if (buttonText === "Download") {
      download();
    }
  };

  const buttonStyles = {
    boxShadow: "7px 3px 5px gold, -7px -3px 5px gold, -7px 3px 5px gold, 7px -3px 5px gold", // Gold shadow when active
  };

  return (
    <Container className="mt-5">
      <a className="hidden" ref={ref} target="_blank"/>
      <Button style={isHighlightButton ? buttonStyles : {}} label={buttonText} buttonState={buttonState} onClick={handleButtonClick} />
    </Container>
  );
};


export default BookingProgressionReport