import React, { useEffect } from "react";
import { prepWindowsData, prepDate } from "../ComponentsData/WindowData";
import { Container, Row, Col } from "react-bootstrap";
import {
  Window,
  MainNumber,
  WindowTitle,
  WindowSubTitle,
  WindowSubNumber,
  UpdateLabel,
  UpdateDiv,
  LateOrderWindowTitle,
} from "./styles";
import { SummaryRow, SummaryNumber } from "../LateOrderReport/styles";
import { DownloadSampleFile } from "../LateOrderReport/downloadSamleFile";
import ProgressionReport from "../BookingProgressionReport/BookingProgressionReport";
import { LateOrderSummary } from "../LateOrderReport/lateOrderSummary";
import {
  SalesGovernanceProvider,
  useSalesGovernanceContext,
} from "../../Context/salesGovernanceContext";
import { ManufacturingProvider } from "../../Context/manufacturingContext";
import { SummaryProvider } from "../../Context/lateOrderSummaryContext";
import { RunningQuarterProvider } from "../../Context/runningQuarterValuesContext";
import { LineGraphProvider } from "../../Context/lineGraphContext";
import { TiledWindows } from "../../Components/TiledWindow/index";
import { RunningQuarterValuesComp } from "../../Components/CardsComponents/RunningQuarterValuesComp";
import { DLCardComp } from "../../Components/CardsComponents/DLCardComp";
import { BacklogComp } from "../../Components/CardsComponents/BacklogComp";
import LineGraphComp from "../../Components/CardsComponents/LineGraphComp";
import { ValuesTableComp } from "../../Components/CardsComponents/ValuesTableComp";
import { Timer } from "../../Components/CountdownTimer";
import { useAuth } from "react-oidc-context";

function getDateString(targetDate) {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let currentDate = new Date(targetDate);
  let formatted_date =
    currentDate.getUTCDate() +
    " " +
    month[currentDate.getUTCMonth()] +
    " " +
    currentDate.getUTCFullYear();
  return formatted_date;
}
export function WindowsBase(props) {
  const { wData, CurrentDate, week } = props;
  let auth = useAuth();
  var isUserRoleManagerOrAdmin = isInArray("Manager", auth.user?.profile?.role) || isInArray("Admin", auth.user?.profile?.role);
  //condition = true;
  let isUserRoleMechanic = isInArray("Mechanic", auth.user?.profile?.role)
  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  return (
    <Container fluid style={{ marginBottom: 50 + "px" }}>
      <Row>
        <Col lg={7} md={4} sm={12} style={{ height: 0 }}>
          <div className="searchBox">
            <p className="searchText">Search</p>
            <span className="tooltiptext">
              Search will be implemented in future versions
            </span>
          </div>
        </Col>

        {(() => {
          if (week === undefined) {
            return (
              <Col lg={7} md={12} sm={12}>
                <Timer Text={"Days left to the next update "} Time={prepDate(wData)} />
              </Col>
            )
          }
          return null;
        })()}

        <Col lg={5} md={12} sm={12}>
          <UpdateDiv>
            <UpdateLabel>
              Week ending {getDateString(prepDate(wData))}
            </UpdateLabel>
          </UpdateDiv>
        </Col>

        <Col lg={6} md={12}>
          <Window>
            <ManufacturingProvider Date={CurrentDate}>
              <TiledWindows />
            </ManufacturingProvider>
          </Window>
        </Col>

        <Col lg={6} md={12}>
          <Window>
            <SummaryProvider Date={CurrentDate}>
              <LateOrderSummary />
            </SummaryProvider>
          </Window>
        </Col>

        {(() => {
          if (isUserRoleManagerOrAdmin) {
            return (
              <>
                <Col lg={6} md={12}>
                  <Window>
                    <BacklogComp data={wData} />
                  </Window>
                </Col>
                <Col lg={6} md={12}>
                  <Window>
                    <RunningQuarterProvider Week={week + 1}>
                      <RunningQuarterValuesComp />
                    </RunningQuarterProvider>
                  </Window>
                </Col>
              </>
            );
          }
          return null;
        })()}

        <Col lg={6} md={12}>
          <Window style={{}}>
            <DLCardComp title={"4 weeks"} DL={wData?.DirectLaborPercentageMonthly} baseDL={wData?.BaseDirectLaborPercentageMonthly} role={auth.user?.profile?.role}></DLCardComp>
          </Window>
        </Col>
        <Col lg={6} md={12}>
          <Window style={{}}>
            <DLCardComp title={"13 weeks"} DL={wData?.DirectLaborPercentageQuarterly} baseDL={wData?.BaseDirectLaborPercentageQuarterly} role={auth.user?.profile?.role}></DLCardComp>
          </Window>
        </Col>

        <Col lg={6} md={12}>
          <Window style={{ display: "block" }}>
            <WindowTitle>DL % Graph</WindowTitle>
            <LineGraphProvider url={'getDLValues'} endDate={new Date()} startDate={new Date(new Date().setMonth(new Date().getMonth() - 6))}>
              <LineGraphComp height={'280px'} notShowYaxis={isUserRoleMechanic}/>
            </LineGraphProvider>
          </Window>
        </Col>

        <Col lg={6} md={12}>
          <Window style={{ display: "block" }}>
            <WindowTitle>Backlog Graph</WindowTitle>
            <LineGraphProvider url={'GetBacklogValues'} endDate={wData?.CurrentWeekFriday ? new Date(wData?.CurrentWeekFriday) : new Date()}
              startDate={wData?.CurrentWeekFriday ? new Date(new Date(wData?.CurrentWeekFriday).setDate(new Date(wData?.CurrentWeekFriday).getDate() - 91))
                : new Date(new Date().setDate(new Date().getDate() - 91))}>
              <LineGraphComp height={'280px'} notShowYaxis={isUserRoleMechanic}/>
            </LineGraphProvider>
          </Window>
        </Col>

        {prepWindowsData(wData, auth.user?.profile?.role)
          .reduce(function (accumulator, currentValue, currentIndex, array) {
            if (currentIndex % 2 === 0)
              accumulator.push(array.slice(currentIndex, currentIndex + 2));
            return accumulator;
          }, [])
          .map((val, index) => {
            return (
              <>
                {(() => {
                  if (
                    isUserRoleManagerOrAdmin == false &&
                    !val[0].mainNumber.toString().includes("$")
                  ) {
                    return (
                      <Col lg={6} md={12}>
                        <Window>
                          <MainNumber>{val[0].mainNumber}</MainNumber>
                          <WindowTitle>
                            {val[0].title} <br /> {val[0].subtitle}
                          </WindowTitle>
                        </Window>
                      </Col>
                    );
                  } else if (isUserRoleManagerOrAdmin || isUserRoleMechanic) {
                    return (
                      <Col lg={6} md={12}>
                        <Window>
                          <MainNumber>{val[0].mainNumber}</MainNumber>
                          <WindowTitle>
                            {val[0].title} <br /> {val[0].subtitle}
                          </WindowTitle>
                        </Window>
                      </Col>
                    );
                  }

                  return null;
                })()}
                {(() => {
                  if (
                    isUserRoleManagerOrAdmin == false &&
                    !val[0].mainNumber.toString().includes("$")
                  ) {
                    return (
                      <Col lg={6} md={12}>
                        <Window>
                          <MainNumber>{val[1].mainNumber}</MainNumber>
                          <WindowTitle>
                            {val[1].title} <br /> {val[1].subtitle}
                          </WindowTitle>
                        </Window>
                      </Col>
                    );
                  } else if (isUserRoleManagerOrAdmin || isUserRoleMechanic) {
                    return (
                      <Col lg={6} md={12}>
                        <Window>
                          <MainNumber>{val[1].mainNumber}</MainNumber>
                          <WindowTitle>
                            {val[1].title} <br /> {val[1].subtitle}
                          </WindowTitle>
                        </Window>
                      </Col>
                    );
                  }
                  return null;
                })()}
              </>
            );
          })}

        {(() => {
          if (isUserRoleManagerOrAdmin) {
            return (
              <>
                <Col lg={6} md={12}>
                  <Window style={{ display: "block", padding: "10px 35px 0" }}>
                    <ValuesTableComp data={wData}></ValuesTableComp>
                  </Window>
                </Col>
              </>
            );
          }
          return null;
        })()}

        {(() => {
          if (isUserRoleManagerOrAdmin) {
            return (
              <Col lg={6} md={12}>
                <Window>
                  <MainNumber>${(+wData?.TotalLaborDollarWeekly).toFixed(2)}K</MainNumber>
                  <WindowTitle>Labor</WindowTitle>
                  <WindowSubTitle>
                    <WindowSubNumber>{wData?.WorkingDayAmount}</WindowSubNumber>{" "}
                    day week
                  </WindowSubTitle>
                </Window>
              </Col>
            );
          }
          return null;
        })()}

        {(() => {
          if (isUserRoleManagerOrAdmin) {
            return (
              <>
                <Col lg={6} md={12}>
                  <Window>
                    <DownloadSampleFile Date={CurrentDate} />
                    <LateOrderWindowTitle>
                      Late Order <br /> Report Past 4 Weeks
                    </LateOrderWindowTitle>
                  </Window>
                </Col>

                <Col lg={6} md={12}>
                  <Window>
                    <ProgressionReport/>
                    <LateOrderWindowTitle>
                      Booking Progression <br /> Last 10 Years
                    </LateOrderWindowTitle>
                  </Window>
                </Col>
              </>
            );
          }
          return null;
        })()}

      </Row>

    </Container>
  );
}

function Windows() {
  let SalesGovernanceContext = useSalesGovernanceContext();

  return (
    <WindowsBase wData={SalesGovernanceContext.propData} CurrentDate={0}></WindowsBase>
  );
}

export default Windows;
