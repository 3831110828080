const setLocalStorageValueWithExpiry = (key: string, value: any, ttl: number) => { // ttl -> Time To Live
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl, // ttl is in milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getLocalStorageValueWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);

  // If the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // Check if the item is expired
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key); // Remove expired item
    return null;
  }

  return item.value;
};

const removeLocalStorageValueWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return
  }

  localStorage.removeItem(key);
};

export {
  setLocalStorageValueWithExpiry,
  getLocalStorageValueWithExpiry,
  removeLocalStorageValueWithExpiry,
}