import axios from "axios";
import React, { useState } from "react";
import { DateTime } from "luxon";
import { useDownloadFile } from "../../Utils/useDownload";
import { Button, ButtonState } from "../button";
import { Alert, Container } from "react-bootstrap";
import {useAuth} from 'react-oidc-context';
import {SGD_API_HOST} from '../../env';

export const DownloadSampleFile: React.FC = (props) => {
  
  // check to take the newest data or for previous weeks
  var Date;
  if(props["Date"] == 0)
    Date = "";
  else 
    Date = "/" + props["Date"];
  
  const auth =useAuth();
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Primary
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const preDownloading = () => setButtonState(ButtonState.Loading);
  const postDownloading = () => setButtonState(ButtonState.Primary);

  const onErrorDownloadFile = () => {
    setButtonState(ButtonState.Primary);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const getFileName = () => {
    if(Date == "")
      return DateTime.local().toISODate() + "_lateOrderReport.pdf";
    else
      return Date.substr(1,10) + "_lateOrderReport.pdf";
  };

  const downloadSampleFile = async () => {
    
    const res = await axios.get(
      SGD_API_HOST.concat("/api/LateOrderReport/GetLatestLateOrderReportPdf" + Date),
      {
        responseType: "blob",
        method: 'get',
        headers: {
            Authorization: `bearer ${auth.user?.access_token}`},
      }
    );
    return res;
  };

  let { ref, download } = useDownloadFile({
    apiDefinition: downloadSampleFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  return (
    <Container className="mt-5">
      <a className="hidden" ref={ref} target="_blank"/>
      <Button style={{}} label="Download" buttonState={buttonState} onClick={download} />
    </Container>
  );
};
/*
<Alert variant="danger" show={showAlert}>
        Something went wrong. Please contact administrators!
      </Alert>*/